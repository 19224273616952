import './less/banner.less';

const defaultConfig = window.configBanner ? window.configBanner : {};

const config = {
   show: defaultConfig.show ? !!defaultConfig.show : false,
   timeout: 5000,
   mode: defaultConfig.mode === 1 ? 'test' : 'prod',
   image: defaultConfig.image
      ? defaultConfig.image
      : '/assets/images/banner-in.jpg',
};

function BannerShow() {
   function action(mode = 'prod') {
      if (mode === 'test') {
         console.log('Banner Show');
      }

      setTimeout(() => {
         $.magnificPopup.open({
            items: {
               src: config.image,
            },
            type: 'image',
            gallery: {
               enabled: false,
            },
         });

         localStorage.setItem(
            'BANNER_SHOW',
            JSON.stringify({
               time: Date.now(),
            })
         );
      }, config.timeout);
   }

   if (!localStorage.getItem('BANNER_SHOW')) {
      action(config.mode);
   } else {
      const bannerData = JSON.parse(localStorage.getItem('BANNER_SHOW'));
      const time = Number(bannerData.time);
      const date = new Date(time);

      if (config.mode === 'test') {
         console.log('Дата показа', date.toLocaleString());
         if (Date.now() >= date.setMinutes(date.getMinutes() + 1)) {
            localStorage.removeItem('BANNER_SHOW');
            action('test');
         }
      } else {
         let nextDate = date.setDate(date.getDate() + 1);
         if (Date.now() >= nextDate) {
            localStorage.removeItem('BANNER_SHOW');
            action();
         }
      }
   }
}

if (config.show) {
   BannerShow();
}
